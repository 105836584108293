<template>
  <div class="smart-traffic">
    <tabs :list="tablist" v-model="sel"></tabs>
    <div class="main">
      <mobi v-if="sel === 0"></mobi><sid v-if="sel === 1"></sid>
    </div>
    <ourserviceBtnVue/>
  </div>
</template>

<script>
import ourserviceBtnVue from "../../../components/service/ourservice-btn.vue";
import mobi from "./mobi.vue";
import sid from "./sid.vue";
import tabs from "/src/components/tabs/index.vue";

export default {
  components: { mobi, sid, tabs, ourserviceBtnVue },
  data() {
    return {
      sel: 0,
      tablist: [
        {
          title: " MOBI Sensing",
          context: "Digital Twin Model, Road Security, Road Sensing, V2X RSU",
        },
        {
          title: "SID System",
          header: "Secured Information Dissemination System",
          context:
            "VMS, Anti-Hacking, Content Real-time Monitor, Sensitive words and info filtering",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.smart-traffic {
  display: flex;
  width: 100%;
  height: 100%;
  background: url("../../../assets/img/smart_traffic.png") center center;
  background-size: cover;
  .main {
    flex: 1;
    height: 100%;
  }
  .height-light {
    width: 100%;
    height: 18.75px;
    background: rgba(249, 0, 0, 0.3);
  }
}
</style>
