<template>
  <div class="st-mobi">
    <div class="header">
      The MOBI Sensing 7x24 collects the street traffic flow, and draws the
      street digital twin scene in real time. According to the intelligent
      transportation algorithm, it builds a polymorphic travel friendly street,
      improves the traffic control effectiveness, and creates an industrial
      ecology of unmanned vehicles/Internet connected vehicles.
    </div>
    <div class="main">
      <div class="option">
        <img :src="require('/src/assets/img/service/path.png')" /><span
          >Path Perception</span
        >
      </div>
      <div class="option" style="top: 50%; left: 12%">
        <img :src="require('/src/assets/img/service/road-vehicle.png')" /><span
          >Road-Vehicle Synchronization
        </span>
      </div>
      <div
        class="option"
        style="top: 65%; left: 50%; transform: translateX(-50%)"
      >
        <img
          :src="require('/src/assets/img/service/autonomous-vehicle.png')"
        /><span>Autonomous Vehicle</span>
      </div>
      <div class="option" style="top: 50%; right: 12%">
        <img
          :src="require('/src/assets/img/service/efficient-travelling.png')"
        /><span>Efficient Travelling</span>
      </div>
      <div class="option" style="right: 0px;">
        <img :src="require('/src/assets/img/service/road-men.png')" /><span style="transform: translate(-67%, 100%);"
          >Road-Men Synchronization</span
        >
      </div>
      <div class="main">
        <img :src="require('/src/assets/img/service/mobi-main.png')" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mobi",
};
</script>

<style lang="less" scoped>
.st-mobi {
  display: flex;
  font-size: 2.5vh;
  font-family: Helvetica, Helvetica-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  line-height: 2.99vh;
  letter-spacing: 1.69px;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 25px 60px;
  .header {
    margin: 30px;
  }
  .main {
    flex: 1;
    width: 100%;
    position: relative;
    .option {
      position: absolute;
      > img {
        width: 16.75vh;
        height: 16.75vh;
      }
       span{
        position: absolute;
        bottom: 0px;
        left: 50%;
         transform: translate(-50%,100%);
         white-space: nowrap;
      }
    }
    .main {
      position: absolute;
      width: 28.28vw;
      height: 38.61vh;
      top: 0;
      left: 50%;
      transform: translate(-50%,-7.8vh);
      img {
        width: 100%;
        height: 100%;
      }
     
    }
  }
}
</style>
