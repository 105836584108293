<template>
  <div class="st-sid">
    <div class="left">
      <div class="block">
        <img
          style="width: 28.25vw; height: 34.78vh"
          :src="
            require('/src/assets/img/service/information-dissemination.png')
          "
        />Information Dissemination Platform
      </div>
      <div class="block">
        <img
          :src="require('/src/assets/img/service/secured-dissemination.png')"
        />Secured Dissemination Gateway
      </div>
    </div>
    <div class="right">
      <div class="row">
        <div class="point"></div>
        <div>
          End-to-End Encrypted Channel and Secured Dissemination Gateway
        </div>
      </div>
      <div class="row">
        <div class="point"></div>
        <div>Real-time content revelation and inspection</div>
      </div>
      <div class="row">
        <div class="point"></div>
        <div>Sensitive words and information filtering and monitoring</div>
      </div>
      <div class="row">
        <div class="point"></div>
        <div>Real-time content monitoring and tamper resistance</div>
      </div>
      <div class="row">
        <div class="point"></div>
        <div>4G/5G backup link</div>
      </div>
      <div class="row">
        <div class="point"></div>
        <div>off-grid monitoring, automatic shutdown</div>
      </div>
      <div class="row">
        <div class="point"></div>
        <div>Multi vendor protocol integration and unified dissemination</div>
      </div>
      <div class="row">
        <div class="point"></div>
        <div>Operation and alarm status of the device is fully monitored</div>
      </div>
      <div class="row">
        <div class="point"></div>
        <div>
          Support Ip layer transparent deployment,no change to the original
          network
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mobi",
};
</script>

<style lang="less" scoped>
.st-sid {
  display: flex;
  font-size: 2.5vh;
  font-family: Helvetica, Helvetica-Regular;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  line-height: 2.99vh;
  letter-spacing: 1.69px;
  height: 100%;
  width: 100%;
  padding: 25px;
  .left {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .block {
      display: flex;
      flex-direction: column;
      align-items: center;
      > img {
        width: 24.25vw;
        height: 25.78vh;
        margin-bottom: 5px;
      }
    }
  }
  .right {
    width: 40.27vw;
    height: 100%;
    margin-left: 2.25vw;
    .row {
      display: flex;
      align-items: center;
      margin: 2.5vh 0px;
      &:nth-child(odd) .point {
        background: #f60909;
      }
      .point {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #ffffff;
        margin-right: 30px;
      }
    }
  }
}
</style>
